<template>
  <div>
    <header-top></header-top>
    <img src="@/assets/image/newHome/background.png" class="image_background" alt="" />
    <div class="center">
      <div class="first_line">世泊云 | 数字云平台</div>
      <div class="second_line">云感知能力, 数字立体化车库</div>
      <div class="groud_button">
        <el-button type="primary" class="btn-primary" @click="go">登陆你的云账户</el-button>
        <el-button type="info" plain class="btn-secondary">账户入驻详情</el-button>
      </div>
    </div>

    <div class="bottom">
      <div class="CommonWidth">
        <span class="bottom-l bottom-text">
          © 2021 广东世泊智能装备科技有限公司 - ShiBoTech. All rights reserved.
        </span>
        <span class="bottom-r bottom-text"> 粤ICP备18124638号-1 </span>
      </div>
    </div>
  </div>
</template>

<script>
import headerTop from "./Header.vue";
export default {
  components: {
    headerTop,
  },
  methods: {
    go(){
      this.$router.push( {name:"login"} )
    }
  },
  mounted() {
    document.querySelector(".btn-secondary").addEventListener("click",()=>{
      this.$message.info("功能暂未开放")
    })
  },
};
</script>

<style lang="scss" scoped>
.image_background {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.center {
  position: absolute;

  top: 35%;
  width: 100vw;
  z-index: 999;
  color: white;
  text-align: center;
  font-size: 3rem;
}
.first_line {
  font-size: 2.5rem;
  margin: 10px;
}
.second_line {
  font-size: 1.5rem;
}
.bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
  color: #ffffff;
  // background-color: #141414;
  .bottom-text {
    font-size: 10px !important;
    font-size: initial;
  }
  .bottom-l {
    position: absolute;
    left: 30px;
    bottom: 20px;
  }
  .bottom-r {
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
}
.groud_button {
  margin:10px 40px;
  margin-left: 5px;
}
.btn-primary {
  margin: 12px;
  font-size: 12px;
}
.btn-secondary {
  color: #ffffff;
  background: transparent;
  font-size: 12px;
}
@media screen and (min-width: 1500px) {
  .first_line {
    font-size: 4rem;
    margin: 10px;
  }
}

.big_size {
  font-size: 4rem;
}
.button_big_size {
  font-size: 1rem;
}
</style>
