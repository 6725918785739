<template>
  <div class="ComponentsHeader">
    <div class="main">
      <div class="CommonWidth">
        <div class="logo">
        
          <router-link :to="{ name: 'home' }" @click.native="oepnnav()">
            <span class="img">
              <img alt="Vue logo" src="@/assets/image/newHome/logo.png" />
              <span style="color: white;"
                >&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;数字感知，科技生活</span
              >
            </span>
          </router-link>
        </div>

        <div class="openbutton" @click="oepnnav()">
          <transition name="fade" mode="out-in" appear>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navmenu"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </transition>
        </div>

        <div class="nav-main collapse" id="navmenu">
          <div v-for="(item, index) in nav_item" :key="index" class="nav-item">
            <router-link :to="{ name: item.toname }" @click.native="oepnnav()" exact>
              <transition name="fade" mode="out-in" appear>
                <template v-if="nav_button_is_show == true">
                  <div class="title-color-hide Transition-03s">
                    &nbsp;&nbsp; {{ item.title }}
                  </div>
                </template>
                <template v-else>
                  <div class="title-color-black Transition-03s">
                    {{ item.title }} &nbsp;&nbsp;
                  </div>
                </template>
              </transition>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Header",
  props: {
    msg: String,
  },
  created() {
    if (document.body.clientWidth > 850) {
      this.nav_is_show = true;
      this.nav_button_is_show = false;
    } else {
      this.nav_is_show = false;
      this.nav_button_is_show = true;
    }
  },
  mounted() {
    const that = this;
    window.screenWidth = document.body.clientWidth;
    that.screenWidth = window.screenWidth;
    document.querySelector(".Transition-03s").addEventListener("click",()=>{
      window.location.href="https://www.sbznck.com/"
    })
    if (that.screenWidth < 800) {
      // that.topImgShow = false
      console.log("小尺寸");

      // var narbarButton=document.querySelector('.ComponentsHeader')
      $(".ComponentsHeader").addClass("navbar");
      //侧边按钮伸缩

      // $(".navbar-main").addClass("collapse");
    } else {
      // that.topImgShow = true
      $(".ComponentsHeader").attr("class", "ComponentsHeader");
      $(".nav-main").attr("class", "nav-main");
      // $(".first_line").addClass("big_size");
    }

    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        if (that.screenWidth < 800) {
          // that.topImgShow = false
          console.log("小尺寸");

          // var narbarButton=document.querySelector('.ComponentsHeader')
          $(".ComponentsHeader").addClass("navbar");
          //侧边按钮伸缩

          // $(".navbar-main").addClass("collapse");
        } else {
          // that.topImgShow = true
          $(".ComponentsHeader").attr("class", "ComponentsHeader");
          // $(".first_line").addClass("big_size");
          $(".nav-main").attr("class", "nav-main");
        }
      })();
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          if (that.screenWidth < 850) {
            that.nav_is_show = false;
            that.nav_button_is_show = true;
          } else {
            that.nav_is_show = true;
            that.nav_button_is_show = false;
          }
          console.log(that.screenWidth);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    oepnnav() {
      if (this.nav_button_is_show == true) {
        if (this.nav_is_show == false) {
          this.nav_is_show = true;
        } else {
          this.nav_is_show = false;
        }
      }
    },
    closenav() {
      this.nav_is_show = false;
    },
  },
  data() {
    return {
      nav_item: [
        {
          title: "了解世泊车库",
          toname: "home",
        },
      ],
      screenWidth: document.body.clientWidth,
      nav_is_show: true,
      nav_button_is_show: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
a{
  color: unset;
}
img {
  image-rendering: -webkit-optimize-contrast;
}
.ComponentsHeader {
  position: fixed;
  width: 100%;
  z-index: 1500;
  background: url("../../assets/image/newHome/header-backgroud.png");

  .openbutton {
    visibility: hidden;

    margin-right: 30px;

    position: absolute;

    top: 300px;
    z-index: 1500;

    img {
      // 提高图片清晰度
    }
  }

  .main {
    height: 80px;
  }
  .logo {
    float: left;
    line-height: 60px;
    height: 100px;
    overflow: hidden;
    .img {
      position: absolute;
      margin-top: 25px;
      left: calc(30px);
      // 提高图片清晰度

      // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    }
  }
  .nav-main {
    float: right;
    .nav-item {
      padding-right: 10px;
      float: left;
      line-height: 100px;
      font-size: 14px;
      .text {
        color: white;
        text-decoration: none;
      }
    }

    .router-link-active {
      border-bottom: 3px solid;
      display: flow-root;
      .title-color-black {
        color: white;
      }
    }

    .title-color-hide {
      color: #ffffff;
    }

    .title-color-hide:hover {
      color: #40b0ff;
    }

    .title-color-black {
      color: white;
    }

    .title-color-black:hover {
      color: #40b0ff;
    }
  }
}

@media screen and (max-width: 850px) {
  .ComponentsHeader {
    .openbutton {
      visibility: inherit;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 25px;
      top: 25px;
      z-index: 1500;

      img {
        width: 100%;
        // 提高图片清晰度
        // image-rendering: -webkit-optimize-contrast;
      }
    }

    .main {
      height: 80px;
    }
    .logo {
      float: left;
      line-height: 80px;
      height: 80px;
      overflow: hidden;
      .img {
        margin-top: -5px;
        margin-left: -5px;
        // 提高图片清晰度
        image-rendering: -webkit-optimize-contrast;
      }
    }

    .nav-main {
      background-color: #333333d4;
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      top: 80px;
      // height: calc(100vh - 80px);
      z-index: 1500;

      .nav-item {
        width: 100%;
        margin-left: 0 !important;
        padding-left: 40px;
        padding-right: 0px;
        border-bottom: 1px solid #b6b6b6;
      }

      .router-link-active {
        border-bottom: 0px solid #0b314f;
        display: flow-root;
        .title-color-black {
          color: #38acff;
        }
      }
    }
  }
}
</style>
